<template>
  <div v-if="$hasFonction(user, 'Voir compte')">
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <!-- <app-collapse class="test">
        <app-collapse-item
          ref="collapse"
          title="Ajouter un compte"
          v-if="$hasFonction(user, 'Ajouter compte')"
        > -->
      <!-- form start -->
      <!-- <validation-observer ref="addForm">
            <b-form class="mt-2 blockForm" @submit.prevent>
              <div class="form">
                <b-row class="formRow">
                  <b-form-group class="col mr-2">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <label for="name">Nom et Prénom</label>
                      <b-form-input
                        id="name"
                        v-model="defaultForm.libelle"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        placeholder="Nom et Prénom"
                        @click="resetInputLibelle"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger">{{ errorsLibelle }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col mr-2">
                    <validation-provider
                      #default="{ errors }"
                      name="devise"
                      rules="required"
                    >
                      <label for="devise">Devise</label>
                      <b-form-select
                        id="devise"
                        v-model="defaultForm.devise"
                        :options="devises"
                        value-field="id"
                        text-field="name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger">{{ errorsDevise }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-row> -->
      <!-- submit button start -->
      <!-- <div class="BlockbButton mt-2">
                  <div>
                    <b-button
                      type="reset"
                      class="mr-2 annuler"
                      variant="outline-secondary"
                      @click="resetInput"
                    >
                      Annuler
                    </b-button>

                    <b-button
                      v-if="etatButton"
                      type="submit"
                      variant="primary"
                      :disabled="isOperating"
                      @click.prevent="addCompte"
                    >
                      Enregistrer
                    </b-button>

                    <b-button v-else variant="primary">
                      <b-icon-arrow-clockwise
                        animation="spin"
                        font-scale="1"
                      ></b-icon-arrow-clockwise>
                      En cours
                    </b-button>
                  </div>
                </div> -->
      <!-- submit button end -->
      <!-- </div>
            </b-form>
          </validation-observer> -->
      <!-- form end -->
      <!-- </app-collapse-item>
      </app-collapse> -->

      <b-card no-body title="" class="mt-2">
        <div class="my-2 mx-2 row">
          <!-- search start -->
          <div class="blockSearch col-lg-8 mb-sm-2">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Recherche"
              class="search col4 mr-5"
              style="width: 40%"
            >
            </b-form-input>
          </div>
          <!-- search end -->

          <!-- bouton export start -->
          <div class="col-lg-4">

            <b-button
            v-if="$hasFonction(user, 'Exporter compte')"
            variant="outline-primary"
            size="sm"
            @click="ExportFomatExcel"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Exporter
          </b-button>


          <b-button
            v-if="$hasFonction(user, 'Exporter compte')"
            variant="outline-primary"
            size="sm"
            class="ml-2"
            @click="ExportAllToFomatExcel"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Tout Exporter
          </b-button>

          </div>

          <!-- bouton export end -->
        </div>

        <!-- table start -->
        <b-table
          v-model="ExcelComptes"
          id="my-table"
          responsive
          :fields="headers"
          :items="comptes"
          :per-page="perPage"
          hover
          striped
          @sort-changed="sortChanged"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isDescending"
          @row-clicked="onRowClicked"
          :current-page="currentPage"
          :filter="filter"
          @filtered="onFiltered"
          show-empty
          class="border-bottom"
        >
          <!-- iconAction start -->
          <template #cell(solde)="data">
            {{ $thousandSeparator(data.item.solde) }}
          </template>
          <template #cell(actions)="data">
            <b-button
              :disabled="data.item.par_defaut == 1 || data.item.solde != 0"
              v-if="$hasFonction(user, 'Supprimer compte')"
              size="sm"
              variant="flat-danger"
              class="btn-icon"
              @click="deleteCompte(data.item)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
          <!-- iconAction end -->

          <template #empty>
            <div class="text-center">
              <span class="text-nowrap">Aucune donnée existante</span>
            </div>
          </template>
          <template #emptyfiltered>
            <div class="text-center">
              <span class="text-nowrap"
                >L'élément recherché n'est pas dans la liste</span
              >
            </div>
          </template>
        </b-table>
        <!-- table end -->

        <div class="my-2 mx-2 row">
          <!-- per-page end -->
          <div class="col">
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 70px"
            ></b-form-select>
            <span class="text-nowrap ml-2" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span> 
	              <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}
                </span>
            <span class="text-nowrap ml-2" v-if="allRows == 0"> 0 éléments</span>
          </div>
          <!-- per-page end -->

          <!-- pagination start -->
          <b-pagination
            class="pagination"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="my-table"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
          <!-- pagination end -->
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import vSelect from "vue-select";

import {
  BCard,
  BTable,
  BCardText,
  BAlert,
  BModal,
  BPagination,
  BButton,
  BIconPencilFill,
  BIconTrashFill,
  BIconPlus,
  BIconCircleFill,
  BIconArrowClockwise,
  BIconFileEarmarkArrowDownFill,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BCollapse,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  components: {
    vSelect,
    BCard,
    BTable,
    BModal,
    BCardText,
    BAlert,
    BCardCode,
    BPagination,
    BButton,
    EmptyList,
    BIconPlus,
    BIconPencilFill,
    BIconTrashFill,
    BIconCircleFill,
    BIconArrowClockwise,
    BIconFileEarmarkArrowDownFill,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BCollapse,
    BFormInvalidFeedback,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  data() {
    return {
      etatButton: true,
      add: false,
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
      isOperating: false,
      required,
      integer,
      alpha,
      alphaDash,
      libelleState: null,
      errorsLibelle: null,
      errorsDevise: null,
      errorsLibelleModal: null,
      errorsDeviseModal: null,
      sortBy: undefined,
      isDescending: undefined,
      modalEdit: false,
      // totalRows: 1,
      perPage: 10,
      allRows: 1,
      currentPage: 1,
      filter: null,
      pageOptions: [10, 25, 50, 100, { value: 1000000, text: "Tout" }],
      comptes: [],
      ExcelComptes: null,
      editedForm: {},
      defaultForm: {},
      devises: [],
    };
  },
  mounted() {
    this.getDevisesList();
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
    totalRows() {
      this.pageOptions = [10, 25, 50, 100, { value: "10000000000000000000000000", text: "Tout" } ]
      return this.allRows;
    },

    headers() {
      let adminHeader = [
        { label: "N°", align: "start", key: "id", sortable: true, },
        { label: "Libellé", align: "start", key: "libelle", sortable: true, },
        { label: "Solde", align: "start", key: "solde", sortable: true, },
        { label: "Devise", align: "start", key: "devise", sortable: true, },
        { label: "Actions", align: "start", key: "actions", sortable: false },
      ];
      let userHeader = [
        { label: "N°", align: "start", key: "id" },
        { label: "Libellé", align: "start", key: "libelle" },
        { label: "Devise", align: "start", key: "devise" },
        { label: "Actions", align: "start", key: "actions", sortable: false },
      ];
      if (this.user && this.user.user_group) {
        if (this.user.user_group.libelle == "Administrateur") {
          return adminHeader;
        } else {
          return userHeader;
        }
      } else {
        return adminHeader;
      }
    },
  },
  created() {
    this.getComptes();
  },
  methods: {
    resetForm() {
      this.defaultForm.libelle = "";
      this.defaultForm.devise = "";
    },
    onFiltered(filteredItems) {
        this.allRows =  filteredItems.length 
        this.currentPage = 1
      },
    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },
    getComptes() {
      this.$http
        .get("/comptes")
        .then((res) => {
          if (res.status) {
            this.hiddenLoader = true;
          }
          this.comptes = res.data.data;
          this.allRows = this.comptes.length
        })
        .catch((e) => {
          console.log("erreur de récupération:" + e.response.data.message);
        });
    },
    addCompte() {
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.etatButton = false;
          this.defaultForm.type = "compte";
          this.$http
            .post("/comptes", this.defaultForm)
            .then((result) => {
              if (result.data.success) {
                this.etatButton = true;
                this.defaultForm = JSON.parse(
                  JSON.stringify({ type: Object, default: () => {} })
                );
                this.add = false;
                this.hidden = false;
                this.showToast("Compte ajouté avec succès", "success");
                if (this.comptes.length) {
                  this.$refs.collapse.updateVisible(false);
                }
                this.getComptes();
              } else {
                this.etatButton = true;
                this.showToast(result.data.message, "danger");
              }
            })
            .catch((e) => {
              this.etatButton = true;
              var err = e.response.data.errors;

              if (err) {
                if (err.libelle) {
                  this.errorsLibelle = err.libelle[0];
                }

                if (err.devise) {
                  this.errorsDevise = err.devise[0];
                }
              }
            });
        }
      });
    },
    sortChanged: function (ctx) {
      this.sortBy = ctx.sortBy
    },
    buildParameters: function () {
      let params = []

      if (this.input) {
        params.push(`corporate_name=${this.input}`)
      }

      if (this.perPage) {
        params.push(`perPage=${this.perPage}`)
      }

      if (this.currentPage) {
        params.push(`page=${this.currentPage}`)
      }

      if (this.sortBy) {
        params.push(`orderBy=${this.sortBy}`)
      }

      if (this.isDescending) {
        params.push('sortBy=DESC')
      }

      return params
    },
    refreshTable: function () {
      this.$root.$emit('table::refresh', 'organizations-table')
    },
    checkFormValidity() {
      const valid = this.$refs.editForm.checkValidity();
      this.libelleState = valid;
      return valid;
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.handleSubmit();
    },
    editCompte(item) {
      this.editedForm = Object.assign({}, item);
      this.modalEdit = true;
    },
    deleteCompte(item) {
      // Message de suppression
      this.$bvModal
        .msgBoxConfirm(
          "Voulez-vous vraiment supprimer cet enregistrement de la liste des comptes ?",
          {
            title: "Supprimer un compte",
            okVariant: "danger",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.$http
              .delete("/comptes/" + item.id)
              .then((result) => {
                if (result.data.success) {
                  this.showToast("Supression effectuée avec succès", "success");
                  this.getComptes();
                } else {
                  this.showToast(result.data.message, "danger");
                }
              })
              .catch((err) => {
                this.showToast(err.response, "error");
              });
          }
        });
    },
    onRowClicked(item, index, event) {
      window.sessionStorage.setItem("compte_id", item.id);
      this.$router.push({
        name: "compte-details",
      });
    },
    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      let data = [];
      let obj = {};

      this.ExcelComptes.forEach((element) => {
        obj = {
          "NOM DES COMPTES": element.libelle,
          SOLDE: element.solde,
          DEVISE: element.devise,
        };
        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            data,
            name: "FlashCar - Liste des Comptes",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

    ExportAllToFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      let data = [];
      let obj = {};

      this.comptes.forEach((element) => {
        obj = {
          "NOM DES COMPTES": element.libelle,
          SOLDE: element.solde,
          DEVISE: element.devise,
        };
        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            data,
            name: "FlashCar - Liste des Comptes",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

    getDevisesList() {
      this.$http.get("/devises").then((response) => {
        this.devises = response.data.data;
      });
    },
  },
};
</script>

<style lang="scss">
#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.vs__selected .vs__deselect {
  fill: seashell;
}

.v-select .vs__dropdown-menu {
  height: 100px;
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>